import { fetchSkus, updateSku } from '@/api/product'
import { withOptions } from '@/assets/js/utils'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useSkusList() {
  const skuListTable = ref([])

  const tableColumns = [
    { text: 'IMAGE', value: 'media', sortable: false },
    { text: 'ID', value: 'sku_number' },
    { text: 'SKU', value: 'sku', sortable: false },
    { text: 'PRICE', value: 'selling_price' },
    { text: 'STOCK', value: 'stock' },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'LOCATION', value: 'branch_id' },
    { text: 'STATUS', value: 'status' },
    { text: 'UPDATED AT', value: 'updated_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const natureFilter = ref(cached[2] || null)
  const statusFilter = ref(cached[3] || null)
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // must follow cached[0...4] variable order above.

  // fetch data
  const loadSkus = () =>
    fetchSkus(
      withOptions(options.value, {
        search: searchQuery.value,
        status: statusFilter.value,
        nature: natureFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        skuListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllSkus = async updates => {
    loading.value = true
    for (const sku of selectedRows.value) {
      const data = { ...updates }
      await updateSku(sku.id, data)
    }
    loadSkus()
    loading.value = false
    selectedRows.value = []
  }
  const cachable = [options, searchQuery, natureFilter, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    // reset page if filter others (i.e. no page change)
    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    selectedRows.value = []
    loadSkus()
    cache()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'draft') return 'warning'
    if (status === 'published') return 'success'
    if (status === 'unpublished') return 'danger'

    return 'primary'
  }

  return {
    skuListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    natureFilter,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadSkus,
    updateAllSkus,
    resolveStatusVariant,
  }
}
